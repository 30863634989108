export enum Payment {
  NotPaied = 0,
  Paied = 1,
  Expired = 2,
}

export enum ConditionType {
  ClientManagement = 1,
  EmployeeManagement = 2,
  ContractManagement = 3,
  BranchManagement = 4,
  SittingManagement = 5,
  AgencyManagement = 6,
  LawsuitManagement = 7,
  TaskManagement = 8,
  ProjectManagement = 9,
  AccountingManagement = 10,
  PermissionManagement = 11,
  CustomerManagement = 12,
}


export enum ConditionValue {
  None = 0,
  Integer = 1,
}

export enum PermissionFlag {
  Other = 0,
  View = 1,  // Read-only access
  Edit = 2,  // Edit access
  Hide = 3,  // Hide (should not be assigned any permission)
  Default = 4 // Default from role
}
