import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FAQComponent } from './faq.component';
import { TranslationModule } from 'src/app/i18n';



@NgModule({
  declarations: [FAQComponent],
  imports: [
    CommonModule,
    TranslationModule
  ],
  exports: [FAQComponent],      // Export the FAQ component

})
export class FAQModule { }
