import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuardGuard implements CanActivate {
  constructor(private router: Router) { }
  permissionList: { isGranted: boolean; name: string; providers: any[] }[] = [];

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    debugger;

    const cachedPermissions = localStorage.getItem('permissionListOfUser');
    this.permissionList = cachedPermissions ? JSON.parse(cachedPermissions) as { isGranted: boolean; name: string; providers: any[] }[] : [];

    const requiredPermission = route.data['permission'];

    if (this.hasPermission(requiredPermission)) {
      this.router.navigate(['/error/404']);
      return false;
    }
    return true;
  }

  private hasPermission(permission: any): boolean {
    debugger
    let res = this.permissionList.some(p => p.name === permission);
    return res;
  }

}
